import Head from 'next/head';

export type SEOContent = {
  title: string;
  description?: string;
  image?: string;
  icon?: string;
};

type SEOMetatagsProps = {
  content: SEOContent;
};

export const SEOMetatags: React.FC<SEOMetatagsProps> = ({ content }) => {
  return (
    <Head>
      <link rel="icon" href={content.icon || '/favicon.png'} key="icon" />
      <title>{content.title}</title>
      <meta
        name="description"
        key="description"
        content={content.description}
      />
      <meta property="og:title" key="og:title" content={content.title} />
      <meta
        property="og:description"
        key="og:description"
        content={content.description}
      />
      <meta property="og:image" key="og:image" content={content.image} />
      <meta property="twitter:card" content="summary_large_image" />
    </Head>
  );
};
