/* istanbul ignore file */
import 'isomorphic-unfetch';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ServerError
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { createLogger } from '~/logging/logging';
import { getApiUrl } from '../getEnv';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from '@aws-amplify/auth';
import { setContext } from '@apollo/client/link/context';

const apiUrl = getApiUrl();
const logger = createLogger('apollo:client');

const transactionalCodes = [401, 403, 404];

function omitTypename(key: string, value: any) {
  return key === '__typename' ? undefined : value;
}

let client: ApolloClient<any>;

export function initApolloClient(): ApolloClient<any> {
  const initClient = (
    endPoint: string,
    credentials: 'include' | 'same-origin'
  ) => {
    const beforeRequest = new ApolloLink((operation, forward) => {
      const { query, operationName, variables } = operation;
      const queryBody =
        (query.loc && query.loc.source && query.loc.source.body) ||
        'Unknown query';
      Sentry.addBreadcrumb({
        category: 'graphql',
        message: operationName,
        level: 'info' as any,
        data: {
          query: queryBody,
          variables: variables
        }
      });
      logger.debug(queryBody);
      if (operation.variables) {
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          omitTypename
        );
      }
      return forward ? forward(operation) : null;
    });

    const authLink = setContext(async (_, { headers }) => {
      try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser();
        if (!user) {
          return headers;
        }
        const idToken = user.getSignInUserSession()?.getIdToken();
        if (!idToken) {
          return headers;
        }
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${idToken.getJwtToken()}`
          }
        };
      } catch (ex) {
        return headers;
      }
    });

    return new ApolloClient({
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (networkError) {
            // Only log if a not a transactional error.
            const serverError = networkError as ServerError;
            if (
              !serverError.statusCode ||
              transactionalCodes.indexOf(serverError.statusCode) === -1
            ) {
              logger.error(networkError);
              Sentry.captureException(
                new Error(`[Network error]: ${JSON.stringify(networkError)}`)
              );
            }
          } else if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              logger.error(message);
              Sentry.captureException(
                new Error(
                  `[GraphQL error]: Message: ${message}, Location: ${
                    locations
                      ? locations
                          .map(
                            (src) => `Line: ${src.line}, column: ${src.column}`
                          )
                          .join('/n')
                      : 'Unknown'
                  }, Path: ${path}`
                )
              );
            });
          }
        }),
        authLink,
        beforeRequest,
        new HttpLink({
          uri: endPoint,
          credentials,
          fetchOptions: {
            credentials: 'include'
          }
        })
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          Idea: {
            fields: {
              user_groups: {
                merge(_existing = [], incoming: any[]) {
                  return incoming;
                }
              }
            }
          }
        }
      }),
      ssrMode: typeof window === 'undefined'
    });
  };

  client = client || initClient(`${apiUrl}/graphql`, 'include');
  return client;
}
