import { gql } from '@apollo/client';

export const organisationUpdateMutation = gql`
  mutation investorOrganisationUpdate($input: InvestorOrganisationInput!) {
    updateInvestorOrganisation(input: $input) {
      id
    }
  }
`;

export const userOrganisationQuery = gql`
  query investorOrganisation {
    investorOrganisation {
      id
      name
      website
      channel
      onboardedOn
      activatedOn
      availableEvaluations
    }
  }
`;

export const organisationEnableUploadQuery = gql`
  query investorOrganisation {
    investorOrganisation {
      enableUpload
      availableEvaluations
    }
  }
`;

export const getUserOrganisation = gql`
  query investorOrganisationUsers {
    investorOrganisation {
      id
      name
      users {
        id
        firstName
        lastName
        email
        role
      }
    }
  }
`;
