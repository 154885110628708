import { BoxProps, ChakraProvider } from '@chakra-ui/react';
import { ErrorBoundary, theme } from 'koble-ui-lib';
import { NextComponentType, NextPageContext } from 'next';
import App from 'next/app';
import Head from 'next/head';
import { ComponentType } from 'react';
import { createLogger } from '~/logging/logging';
import {
  getApiUrl,
  getAppUrl,
  getEnv,
  getHost,
  getLaunchDarklyClientSideId,
  getRelease,
  getSentryDSN,
  isServer
} from '~/utils/getEnv';

import { ApolloProvider } from '@apollo/client';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { BaseContext } from 'next/dist/shared/lib/utils';
import { UserAuthProvider } from '~/api/auth';
import clientEnv from '~/client-env.json';
import { InvestorLayout } from '~/components/Layout';
import { MobileGate } from '~/components/MobileGate';
import { SEOMetatags } from '~/components/SEOMetatags';
import { initApolloClient } from '~/utils/apollo/client';

const logger = createLogger('app');
const ldClientSideId = getLaunchDarklyClientSideId() ?? '';

const env = getEnv();

const environment = `${env}-${isServer() ? 'SERVER' : 'CLIENT'}`;

logger.info(
  `Configured (${getRelease()}): ${environment} Api endpoint: ${getApiUrl()}, Sentry: ${getSentryDSN()}`
);

export type PlatformGetLayout<P = any> = (
  component: JSX.Element,
  pageProps: P
) => JSX.Element;

export declare type PlatformNextComponentType<
  C extends BaseContext = BaseContext,
  IP = Record<string, unknown>,
  P = Record<string, unknown>
> = NextComponentType<C, IP, P> & {
  getLayout?: PlatformGetLayout;
};

export type PlatformNextPage<
  P = Record<string, unknown>,
  IP = P
> = PlatformNextComponentType<NextPageContext, IP, P>;

const apolloClient = initApolloClient();

const appUrl = getAppUrl();

const metaDefaults = {
  title: 'Koble - AI infrastructure for startup investors',
  description:
    'Koble empowers investors to automatically source startups, and evaluate risk associated with their Team, Market and Traction to help investors make better decisions.',
  image: `https://${getHost()}/og.png`
};

Amplify.configure({
  Auth: {
    region: 'eu-west-2',
    userPoolId: clientEnv.COGNITO_USER_POOL_ID,
    userPoolWebClientId: clientEnv.COGNITO_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    oauth: {
      domain: clientEnv.COGNITO_OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: appUrl,
      redirectSignOut: appUrl,
      responseType: 'code'
    }
  }
});

class PlatformApp extends App<
  {
    Component: PlatformNextComponentType<NextPageContext, any>;
  },
  { footerProps: BoxProps }
> {
  render() {
    const { Component, pageProps } = this.props;
    const getLayout = Component.getLayout || ((page) => page);
    return (
      <ErrorBoundary>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1"
          />
        </Head>
        <SEOMetatags content={metaDefaults} />

        <ApolloProvider client={apolloClient}>
          <Authenticator.Provider>
            <UserAuthProvider>
              <ChakraProvider theme={theme} resetCSS>
                <MobileGate />
                <InvestorLayout footerProps={pageProps.footerProps}>
                  {getLayout(<Component {...pageProps} />, pageProps)}
                </InvestorLayout>
              </ChakraProvider>
            </UserAuthProvider>
          </Authenticator.Provider>
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}

export default withLDProvider({
  clientSideID: ldClientSideId
})(PlatformApp as unknown as ComponentType);
