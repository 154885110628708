/* istanbul ignore file */
import { createEnvConfig } from 'koble-ui-lib';
import { useEffect, useState } from 'react';
import clientEnv from '../client-env.json';

const {
  getHost,
  getEnv,
  getAppUrl,
  getApiUrl,
  isServer,
  getRelease,
  isDevelopment,
  isProduction,
  isCI,
  isTesting,
  getSentryDSN,
  getLaunchDarklyClientSideId
} = createEnvConfig({ clientEnv, appName: 'investor' });

export {
  getApiUrl,
  getAppUrl,
  getEnv,
  getHost,
  getLaunchDarklyClientSideId,
  getRelease,
  getSentryDSN,
  isCI,
  isDevelopment,
  isProduction,
  isServer,
  isTesting
};

export function useClientUrl() {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const host = getHost();
    if (host.indexOf('localhost') !== -1) {
      setUrl(`http://localhost:4003`);
    } else {
      setUrl(`https://${host.replace('investor', 'app')}`);
    }
  }, []);

  return url;
}
