import { gql } from '@apollo/client';

export const teamScoreFragment = gql`
  fragment teamScoreFields on TeamScore {
    startup
    domain
    technical
    growth
    logistics
    total
  }
`;

export const ideaForTeamReportQuery = gql`
  query getIdeaTeamScore($ideaId: UUID!) {
    idea(id: $ideaId) {
      id
      name
      description
      businessModels
      startedOn
      isProcessing
      website
      linkedinUrl
      amountRaised {
        amount
        currency
      }
      teamMembers {
        id
        name
        firstName
        lastName
        isCofounder
        linkedinUrl
        score {
          ...teamScoreFields
        }
      }
      industries {
        id
        name
        display_name
        is_technology
        is_industry
      }
      teamScore {
        ...teamScoreFields
      }
    }
  }
  ${teamScoreFragment}
`;

export const compareIdeasFoundersQuery = gql`
  query getIdeasEvaluateFoundersComparison(
    $id: UUID!
    $mode: ScoreComparisonMode!
  ) {
    comparison: ideasFoundersScoresComparison(teamMemberId: $id, mode: $mode) {
      scores {
        ...teamScoreFields
      }
      percentile
    }
  }
  ${teamScoreFragment}
`;

export const compareIdeasQuery = gql`
  query getIdeasComparison($id: UUID!, $mode: ScoreComparisonMode!) {
    comparison: ideasScoresComparison(ideaId: $id, mode: $mode) {
      scores {
        ...teamScoreFields
      }
      percentile
    }
  }
  ${teamScoreFragment}
`;

export const teamMemberForReportQuery = gql`
  query getTeamMemberForReport($teamMemberId: UUID!) {
    teamMember(id: $teamMemberId) {
      id
      name
      firstName
      lastName
      profilePicture
      description
      isCofounder
      linkedinUrl
      previous_fundraising {
        amount
        currency
      }
      workExperience {
        title
        companyName
        companyUrl
        companyServiceId
        duration
        foundingTeam
        areaOfExpertise
        seniorityLevel
        startDate
        endDate
        isCurrentlyWorking
        degree {
          id
          name
        }
      }
      education {
        graduationYear
        educationLevel
        degree {
          id
          name
        }
        university {
          id
          name
        }
      }
      commitment
      motivation
      fundraising_experience {
        amount {
          amount
          currency
        }
        company
        stage
        involvement
      }
      entrepreneurProgramExperience {
        id
        type
        graduationYear
        program {
          id
          name
        }
      }
      score {
        ...teamScoreFields
      }
      ideas {
        id
        name
        description
        businessModels
        startedOn
        isProcessing
        teamScore {
          startup
          domain
          technical
          growth
          logistics
          total
        }
        company {
          website
          linkedin
        }
        amountRaised {
          amount
          currency
        }
        teamMembers {
          id
          name
          description
          linkedinUrl
          isCofounder
          score {
            startup
            domain
            technical
            growth
            logistics
            total
          }
        }
        industries {
          id
          name
          display_name
          is_technology
          is_industry
        }
      }
    }
  }
  ${teamScoreFragment}
`;

export const otherTeamMembersPercentileQuery = gql`
  query getTeamMemberScoresPercentile(
    $percentile: Float!
    $excludedTeamMemberIds: [UUID!]
  ) {
    teamMemberScoresPercentile(
      percentile: $percentile
      excludedTeamMemberIds: $excludedTeamMemberIds
    ) {
      ...teamScoreFields
    }
  }
  ${teamScoreFragment}
`;

export const otherTeamsPercentileQuery = gql`
  query getTeamScoresPercentile(
    $percentile: Float!
    $excludedIdeaIds: [UUID!]
  ) {
    teamScoresPercentile(
      percentile: $percentile
      excludedIdeaIds: $excludedIdeaIds
    ) {
      ...teamScoreFields
    }
  }
  ${teamScoreFragment}
`;
