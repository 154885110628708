import { gql } from '@apollo/client';

const investorInviteFragment = gql`
  fragment investorInviteFields on InvestorOrganisationInvite {
    id
    email
    role
  }
`;

const investorInvitePublicFragment = gql`
  fragment investorInvitePublicFields on InvestorInvitePublic {
    id
    email
    role
    organisationName
  }
`;

export const createInvitesMutation = gql`
  mutation createInvestorInviteMutation($input: InvestorInvitesCreateInput!) {
    investorInvitesCreate(input: $input) {
      ...investorInviteFields
    }
  }
  ${investorInviteFragment}
`;

export const deleteInviteMutation = gql`
  mutation deleteInvestorInviteMutation($input: InvestorInviteMutateInput!) {
    investorInviteDelete(input: $input) {
      ...investorInviteFields
    }
  }
  ${investorInviteFragment}
`;

export const resenedInviteMutation = gql`
  mutation resendInvestorInviteMutation($input: InvestorInviteMutateInput!) {
    investorInviteResend(input: $input) {
      ...investorInviteFields
    }
  }
  ${investorInviteFragment}
`;

export const getInviteQuery = gql`
  query getInvestorInvite($id: String!) {
    investorInvite(id: $id) {
      ...investorInvitePublicFields
    }
  }
  ${investorInvitePublicFragment}
`;

export const getInvitesQuery = gql`
  query getInvestorInvites {
    investorInvites {
      ...investorInviteFields
    }
  }
  ${investorInviteFragment}
`;
