import { gql } from '@apollo/client';

export const notificationsQuery = gql`
  query notifications($from: DateTime, $skip: Int, $take: Int) {
    investorNotifications(from: $from, skip: $skip, take: $take) {
      items {
        id
        createdOn
        isRead
        message
        idea {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

export const markAsReadMutation = gql`
  mutation markAsRead($id: UUID!) {
    markInvestorNotificationRead(id: $id) {
      id
    }
  }
`;

export const markAllAsReadMutation = gql`
  mutation markAllAsRead {
    markAllInvestorNotificationsRead
  }
`;
