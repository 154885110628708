import { Flex, MenuDivider, MenuItem } from '@chakra-ui/react';
import { AppAccountMenu } from 'koble-ui-lib';
import { useRouter } from 'next/router';
import { User } from '../../api';
import { NotificationMenu } from '../notifications/NotificationsMenu';
import { accountPages } from './accountPages';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { IInvestorOrganisationOutput } from 'koble-models';

const AccountMenu = ({ user }: { user?: User }) => {
  const router = useRouter();
  return (
    <AppAccountMenu user={user}>
      <MenuItem onClick={() => router.push(`/archive`)}>Archive</MenuItem>
      <MenuDivider />
      {accountPages.map((page) => (
        <MenuItem
          key={page.path}
          onClick={() => router.push(`/account/${page.path}`)}
        >
          {page.label}
        </MenuItem>
      ))}
      <MenuDivider />
      <MenuItem onClick={() => router.push(`/logout`)}>Logout</MenuItem>
    </AppAccountMenu>
  );
};

export const UserMenus = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const token = user?.getSignInUserSession()?.getIdToken().decodePayload();

  return (
    <Flex gap={4} direction={['column', 'column', null, 'row']}>
      <NotificationMenu />
      <AccountMenu
        user={{
          firstName: token?.given_name,
          lastName: token?.family_name,
          profilePicture: token?.picture,
          signupNotifications: false,
          id: token?.sub,
          email: token?.email,
          organisation: {
            id: token?.['custom:orgId']
          } as IInvestorOrganisationOutput
        }}
      />
    </Flex>
  );
};
