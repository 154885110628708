import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { INotification } from 'koble-models';
import { CompanyLogo, toDateTime } from 'koble-ui-lib';

export const NotificationRow = ({
  notification: { isRead, message, idea, createdOn },
  ...flexProps
}: {
  notification: INotification;
} & FlexProps) => (
  <Flex
    bg={isRead ? 'white' : 'lime.50'}
    borderTop="card"
    gap={4}
    justifyContent="space-between"
    py={2}
    px={3}
    {...flexProps}
  >
    <Flex
      alignItems="center"
      _before={{
        content: '" "',
        display: 'block',
        w: '8px',
        h: '8px',
        bg: 'lime.500',
        borderRadius: 'full',
        mr: 3,
        visibility: isRead ? 'hidden' : undefined
      }}
    >
      <CompanyLogo logo={idea?.logoUrl} />
      <Box>
        <Box fontWeight="bold">{idea?.name}</Box>
        {message}
      </Box>
    </Flex>
    <Box
      color="lighterText"
      fontSize="sm"
      justifySelf="flex-end"
      fontWeight="bold"
    >
      {toDateTime(createdOn).toRelative()}
    </Box>
  </Flex>
);
