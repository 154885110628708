import { useEffect } from 'react';
import {
  AppLayout,
  AppLayoutProps,
  TabbedPage,
  Children,
  AppNavigationBar,
  AppNavigationGroup,
  AppNavigationLink,
  Main
} from 'koble-ui-lib';
import { useRouter } from 'next/router';
import { getAnalytics } from '~/utils/analytics';
import { accountPages } from './account/accountPages';
import { UserMenus } from './account/UserMenus';
import { useFlags } from 'launchdarkly-react-client-sdk';

const fullNavRoutes = [
  '/archive',
  '/discover',
  '/lists',
  '/portfolio',
  '/inbound',
  '/invitations',
  '/ideas',
  '/founders',
  '/source',
  '/watchlist',
  '/evaluate',
  '/account',
  '/notifications'
];
const loggedInRoutes = [...fullNavRoutes, '/account'];

const analytics = getAnalytics();

interface InvestorLayoutProps extends AppLayoutProps {
  showNavigation?: (route?: string) => boolean;
  showNavigationBar?: (route?: string) => boolean;
}

export const InvestorLayout = ({
  children,
  showNavigation = (route?: string) =>
    route
      ? fullNavRoutes.find((r) => route.indexOf(r) > -1) !== undefined
      : false,
  showNavigationBar = (route?: string) =>
    !route?.split('/').pop()?.includes('_'), // Report download pages
  ...rest
}: InvestorLayoutProps) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', analytics.page);

    return () => {
      router.events.off('routeChangeComplete', analytics.page);
    };
  }, [router]);

  const featureFlags = useFlags();
  const showNavBar =
    showNavigationBar(router.route) && featureFlags.investorShowNavbar;
  const showNavigationLinks =
    showNavigation(router.route) && featureFlags.investorShowNavigationLinks;

  const isLoggedIn =
    loggedInRoutes.find((r) => router.route?.indexOf(r) > -1) !== undefined;

  return (
    <AppLayout
      bg="appBackground"
      variant="horizontal"
      navItemVariant="solid"
      {...rest}
    >
      {showNavBar && (
        <AppNavigationBar enableHref={featureFlags.investorEnableLogoLink}>
          {showNavigationLinks && (
            <AppNavigationGroup flexGrow="15">
              <AppNavigationLink route="/discover" as="/discover">
                Discover
              </AppNavigationLink>
              <AppNavigationLink route="/evaluate" as="/evaluate">
                Evaluate
              </AppNavigationLink>
              <AppNavigationLink
                route="/portfolio"
                as="/portfolio"
                isRouteActive={(route) => route.startsWith('/portfolio')}
              >
                My portfolio
              </AppNavigationLink>
              <AppNavigationLink
                route="/lists"
                as="/lists"
                isRouteActive={(route) => route.startsWith('/lists')}
              >
                My lists
              </AppNavigationLink>
            </AppNavigationGroup>
          )}
          {isLoggedIn && <UserMenus />}
        </AppNavigationBar>
      )}
      {children}
    </AppLayout>
  );
};

export const AccountLayout = ({ children }: Children) => {
  return (
    <Main>
      <TabbedPage title="Your Account" tabs={accountPages}>
        {children}
      </TabbedPage>
    </Main>
  );
};
