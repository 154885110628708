import { useEffect, useState } from 'react';

export const useBrowserNotifications = () => {
  const [isRequesting, setRequesting] = useState(false);
  const currentPermission =
    typeof Notification !== 'undefined' ? Notification.permission : 'denied';
  const requestPermission = async (): Promise<NotificationPermission> => {
    setRequesting(true);
    try {
      return await Notification.requestPermission();
    } finally {
      setRequesting(false);
    }
  };
  useEffect(() => {
    if (!isRequesting && currentPermission === 'default') {
      requestPermission();
    }
  }, [currentPermission]);

  return {
    currentPermission,
    isRequesting,
    requestPermission,
    sendNotification: (title: string, options: NotificationOptions) =>
      new Notification(title, options)
  };
};
