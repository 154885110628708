import debug from 'debug';
import { isCI } from '~/utils/getEnv';
const baseLogger = debug('investor');

if (isCI()) {
  console.log('Enabling logging on CI server');
  debug.enable('investor:*');
}

export const createLogger = (namespace: string) => {
  const error = baseLogger.extend(`error:${namespace}`);
  error.log = console.error.bind(console);
  return {
    trace: baseLogger.extend(`trace:${namespace}`),
    debug: baseLogger.extend(`debug:${namespace}`),
    info: baseLogger.extend(`info:${namespace}`),
    warn: baseLogger.extend(`warn:${namespace}`),
    error
  };
};
