import { gql } from '@apollo/client';

const investorFounderInviteFragment = gql`
  fragment investFounderInviteFields on InvestorFounderInvite {
    id
    email
    firstName
    lastName
    ideaName
    expiresOn
    sentOn
  }
`;

export const invitationsQuery = gql`
  query getInvitiations {
    investorFounderInvites {
      ...investFounderInviteFields
    }
  }
  ${investorFounderInviteFragment}
`;

export const inviteFounderCreateMutation = gql`
  mutation inviteFounder($input: InvestorFounderInviteInput!) {
    investorFounderInviteCreate(input: $input) {
      ...investFounderInviteFields
    }
  }
  ${investorFounderInviteFragment}
`;

export const inviteFounderDeleteMutation = gql`
  mutation deleteFounderInvite($input: InvestorFounderInviteMutateInput!) {
    investorFounderInviteDelete(input: $input) {
      ...investFounderInviteFields
    }
  }
  ${investorFounderInviteFragment}
`;

export const inviteFounderResendMutation = gql`
  mutation resendFounderInvite($input: InvestorFounderInviteMutateInput!) {
    investorFounderInviteResend(input: $input) {
      ...investFounderInviteFields
    }
  }
  ${investorFounderInviteFragment}
`;
