import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { User, userQuery } from '~/api';
import { useRouter } from 'next/router';

export const useKobleContext = () => {
  const router = useRouter();
  const {
    authStatus,
    error,
    isPending,
    user: cognitoUser
  } = useAuthenticator((context) => [
    context.authStatus,
    context.error,
    context.isPending,
    context.user
  ]);

  const [getUser, { data: userData, error: userError, loading: userLoading }] =
    useLazyQuery<{ investorUser: User }>(userQuery);

  useEffect(() => {
    if (cognitoUser) {
      getUser();
    }
  }, [cognitoUser]);

  const isActivated =
    !isPending &&
    !userLoading &&
    userData?.investorUser.organisation.activatedOn &&
    userData?.investorUser.organisation.onboardedOn;

  useEffect(() => {
    if (
      authStatus === 'authenticated' &&
      isPending === false &&
      !userLoading &&
      userData &&
      (userData.investorUser.organisation.onboardedOn === null ||
        userData.investorUser.organisation.activatedOn === null) &&
      router.route.indexOf('onboarding') === -1 &&
      router.isReady
    ) {
      router.replace('/onboarding');
    }
  }, [
    authStatus,
    isPending,
    userLoading,
    userData,
    router.route,
    router.isReady
  ]);

  return {
    authStatus,
    error: error || userError,
    cognitoUser,
    user: userData?.investorUser,
    isActivated,
    // isLoggedIn,
    isPending,
    userLoading
  };
};
