import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { LogoWord } from 'koble-ui-lib';
import React from 'react';
import { getAppUrl } from '~/utils/getEnv';

const appUrl = getAppUrl();

export const MobileGate: React.FC = () => {
  return (
    <Flex
      position="fixed"
      width="100%"
      height="100%"
      zIndex={2000}
      bg="appBackground"
      alignItems="center"
      justifyContent="center"
      display={['flex', null, null, 'none']}
      sx={{
        '@media print': {
          display: 'none'
        }
      }}
    >
      <Flex
        boxShadow="panel"
        borderRadius="xl"
        px={[7, 15]}
        py={[12, 16]}
        marginLeft="6"
        marginRight="6"
        bg={'white'}
        width="440px"
        // justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Box>
          <LogoWord width="95px" height="auto" mb="8" />
          <Heading mb="5" size="md">
            Re-engineering startup investing with AI
          </Heading>
          <Text mb="3">
            We recommend using Koble on your desktop for the best experience.
          </Text>
          <Text mb="3">
            Visit the link below on your desktop or a device with a larger
            screen.
          </Text>
        </Box>
        <Link href={appUrl} fontWeight="semibold" color={'primary'}>
          {appUrl}
        </Link>
      </Flex>
    </Flex>
  );
};
